import { Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './style.less';
import { bool, func } from 'prop-types';
import {
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

/**
 * 搜索菜单
 * @returns
 */
const propTypes = {
  searchTrue: bool.isRequired,
  handleKeyDown: func.isRequired,
};

const SearchMenu = (props) => {
  const { searchTrue } = props;
  const isLock = useRef(false);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(searchTrue);
  useEffect(() => {
    setOpen(searchTrue);
  }, [searchTrue]);
  const menus = useMemo(() => (open ? JSON.parse(localStorage.getItem('permissions')) : []), [open]);

  /**
   * 重置css
   */
  const resetClass = () => {
    const list = document.querySelectorAll('.DocSearch-Hit');
    list.forEach((el, index) => {
      list[index].className = 'DocSearch-Hit';
    });
  };
  /**
   * 监听鼠标
   * @param {number} keyCode
   * @returns
   */
  const handleKeyDown = ({ keyCode }) => {
    const list = document.querySelectorAll('.DocSearch-Hit');
    if (keyCode === 13) {
      document.querySelector('.DocSearch-Hit.active').querySelector('a').click();
    }
    if (keyCode === 40) {
      const selectId = [...list].indexOf(document.querySelector('.DocSearch-Hit.active'));
      resetClass();
      let current = selectId === -1 ? 0 : selectId + 1;
      if (current > list.length - 1) {
        current = 0;
      }
      list[current].className += ' active';
      isLock.current = true;
      list[current]?.scrollIntoView({ block: 'nearest' });
    }
    if (keyCode === 38) {
      const selectId = [...list].indexOf(document.querySelector('.DocSearch-Hit.active'));
      resetClass();
      let current = selectId === -1 ? 0 : selectId - 1;
      if (current < 0) {
        current = list.length - 1;
      }
      list[current].className += ' active';
      isLock.current = true;
      list[current]?.scrollIntoView({ block: 'nearest' });
    }
  };
  useEffect(() => {
    if (document.querySelector('.DocSearch-Hit.active')) {
      resetClass();
    }
  }, [search]);
  useEffect(() => {
    const dropdown = document.querySelector('.DocSearch-Dropdown');
    if (open) {
      document.addEventListener('keydown', handleKeyDown, false);
      dropdown.addEventListener('wheel', () => {
        isLock.current = true;
      }, false);
      document.addEventListener('mouseover', () => {
        isLock.current = false;
      }, false);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
      dropdown?.removeEventListener('wheel', () => {}, false);
      document.removeEventListener('mouseover', () => {}, false);
    };
  }, [open, handleKeyDown]);
  const openState = () => {
    setOpen(false);
    props.handleKeyDown(false);
  };

  const renderMenu = ({ name, url, id }) => {
    if (name.includes(search) || url.includes(search)) {
      return (
        <li
          key={id}
          className="DocSearch-Hit"
          onMouseEnter={(e) => {
            if (isLock.current) return;
            resetClass();
            e.currentTarget.className += ' active';
          }}
        >
          <a href={url}>
            <div className="DocSearch-Hit-Container">
              <div className="DocSearch-Hit-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"><path d="M17 5H3h14zm0 5H3h14zm0 5H3h14z" stroke="currentColor" fill="none" fillRule="evenodd" strokeLinejoin="round" /></svg>
              </div>
              <div className="DocSearch-Hit-content-wrapper">
                <span className="DocSearch-Hit-title">
                  {search ? name.split(search)
                    .reduce((prev, c, i) => {
                      if (!i) {
                        return [c];
                      }
                      return prev.concat(<mark key={search + c}>{ search }</mark>, c);
                    }, []) : name}
                </span>

              </div>
              <div className="DocSearch-Hit-action">
                <svg className="DocSearch-Hit-Select-Icon" width="20" height="20" viewBox="0 0 20 20">
                  <g stroke="currentColor" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M18 3v4c0 2-2 4-4 4H2" />
                    <path d="M8 17l-6-6 6-6" />
                  </g>
                </svg>
              </div>
            </div>
          </a>
        </li>
      );
    }
    return null;
  };
  const renderTitle = (menu) => {
    if (menu.url.includes(search) || menu.name.includes(search)
    || menu?.children?.filter((f) => (f?.children
      ? f?.children?.filter((c) => c?.url?.includes(search)
    || c.name?.includes(search)).length !== 0 : f?.url?.includes(search)
    || f?.name?.includes(search))).length !== 0) {
      return <div key={menu.id} className="DocSearch-Hit-source">{menu.name}</div>;
    }
    return null;
  };

  return (
    <div className="mr-4 cursor-pointer">
      <SearchOutlined onClick={() => setOpen(true)} style={{ color: '#ccc', fontSize: 24 }} />
      <Modal
        className="SearchMenu"
        open={open}
        footer={null}
        onCancel={openState}
        bodyStyle={{
          padding: 0,
        }}
        style={{ top: 70 }}
        closeIcon={<div />}
      >
        <div className="DocSearch-Modal">
          <header className="DocSearch-SearchBar">
            <div className="DocSearch-Form">
              <div className="DocSearch-MagnifierLabel" htmlFor="docsearch-input" id="docsearch-label">
                <svg width="20" height="20" className="DocSearch-Search-Icon" viewBox="0 0 20 20">
                  <path d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z" stroke="currentColor" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <div className="DocSearch-LoadingIndicator">
                <svg viewBox="0 0 38 38" stroke="currentColor" strokeOpacity=".5">
                  <g fill="none" fillRule="evenodd">
                    <g transform="translate(1 1)" strokeWidth="2">
                      <circle strokeOpacity=".3" cx="18" cy="18" r="18" />
                      <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
                      </path>
                    </g>
                  </g>
                </svg>
              </div>
              <input
                ref={(input) => {
                  if (input != null) {
                    input.focus();
                  }
                }}
                className="DocSearch-Input"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                enterKeyHint="go"
                spellCheck="false"
                placeholder="请输入功能名称"
                maxLength="64"
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              {search ? (
                <button
                  type="button"
                  className="DocSearch-Reset"
                  onClick={() => {
                    setSearch('');
                  }}
                >
                  <svg width="20" height="20" viewBox="0 0 20 20">
                    <path d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z" stroke="currentColor" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              ) : null}
            </div>
          </header>
          <div className="DocSearch-Dropdown">
            <div className="DocSearch-Dropdown-Container">
              <section className="DocSearch-Hits">
                {menus?.map((item) => (
                  <div key={item.id}>
                    {renderTitle(item)}
                    <ul className="docsearch-list">
                      {item.children?.map((subitem) => {
                        if (subitem?.children?.length) {
                          return subitem?.children?.map((citem) => (
                            renderMenu({ name: citem.name, url: citem.url, id: citem.id })
                          ));
                        }
                        return (
                          renderMenu({ name: subitem.name, url: subitem.url, id: subitem.id })
                        );
                      })}
                    </ul>
                  </div>
                ))}
              </section>
            </div>
          </div>
          <footer className="DocSearch-Footer">
            <ul className="DocSearch-Commands">
              <li>
                <span className="DocSearch-Commands-Key"><svg width="15" height="15"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"><path d="M12 3.53088v3c0 1-1 2-2 2H4M7 11.53088l-3-3 3-3" /></g></svg></span>
                <span className="DocSearch-Label">选中跳转</span>
              </li>
              <li>
                <span className="DocSearch-Commands-Key"><svg width="15" height="15"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"><path d="M7.5 3.5v8M10.5 8.5l-3 3-3-3" /></g></svg></span>
                <span className="DocSearch-Commands-Key"><svg width="15" height="15"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"><path d="M7.5 11.5v-8M10.5 6.5l-3-3-3 3" /></g></svg></span>
                <span className="DocSearch-Label">上下选择</span>
              </li>
              <li>
                <span className="DocSearch-Commands-Key"><svg width="15" height="15"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"><path d="M13.6167 8.936c-.1065.3583-.6883.962-1.4875.962-.7993 0-1.653-.9165-1.653-2.1258v-.5678c0-1.2548.7896-2.1016 1.653-2.1016.8634 0 1.3601.4778 1.4875 1.0724M9 6c-.1352-.4735-.7506-.9219-1.46-.8972-.7092.0246-1.344.57-1.344 1.2166s.4198.8812 1.3445.9805C8.465 7.3992 8.968 7.9337 9 8.5c.032.5663-.454 1.398-1.4595 1.398C6.6593 9.898 6 9 5.963 8.4851m-1.4748.5368c-.2635.5941-.8099.876-1.5443.876s-1.7073-.6248-1.7073-2.204v-.4603c0-1.0416.721-2.131 1.7073-2.131.9864 0 1.6425 1.031 1.5443 2.2492h-2.956" /></g></svg></span>
                <span className="DocSearch-Label">关闭</span>
              </li>
            </ul>
          </footer>
        </div>
      </Modal>
    </div>
  );
};
SearchMenu.propTypes = propTypes;
export default memo(SearchMenu);
