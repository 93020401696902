import { userService } from '@/service';
import { logout } from '@/global';
import messageStore from './message-store';

export const getCompanyInfo = async () => {
  const companyInfo = await userService.getCompanyInfo();
  localStorage.setItem('companyInfo', JSON.stringify(companyInfo));
  return companyInfo;
};

export const readMsg = (id, isRead) => {
  const messageList = localStorage.getItem('messageList');
  const msg = messageList.find((item) => item.id === id);
  if (msg) {
    // 将该条消息标为已读
    msg.isRead = 1;
  }
  if (isRead === 0) {
    // 如果该条消息未读，则将未读总数减一
    let unreadsord = localStorage.getItem('unreads');
    unreadsord -= 1;
    localStorage.setItem('unreads', unreadsord);
  }
};

export const aloneUserInfo = async () => {
  const userInfo = await userService.getUserInfo().catch((e) => {
    if (e.code === 302) {
      logout(e.data);
    }
  });
  if (userInfo) {
    localStorage.setItem('userData', JSON.stringify(userInfo));
  }

  return userInfo;
};

export const setUserInfo = (userInfo, companyInfo, menus) => {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
  localStorage.setItem('companyInfo', JSON.stringify(companyInfo));
  localStorage.setItem('menuInfo', JSON.stringify(menus));
  localStorage.setItem('currentMenu', JSON.stringify(menus));
};

export const getUserInfo = async () => {
  let menus = localStorage.getItem('permissions');
  let userInfo = localStorage.getItem('userData');
  let companyInfo = localStorage.getItem('companyInfo');
  if (!userInfo) {
    userInfo = await aloneUserInfo();
  }
  if (!companyInfo) {
    // 如果localstorage里没有这些数据，需要从服务器获取
    // userInfo = await this.aloneUserInfo();
    companyInfo = await getCompanyInfo();
    menus = await userService.getMenu();
    if (!Array.isArray(menus)) {
      menus = [];
    }
    localStorage.setItem('permissions', JSON.stringify(menus));
  } else {
    // 如果localstrage里已经有了，则直接取
    menus = JSON.parse(menus);

    userInfo = JSON.parse(userInfo);
    companyInfo = JSON.parse(companyInfo);
  }
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
  localStorage.setItem('companyInfo', JSON.stringify(companyInfo));
  localStorage.setItem('menuInfo', JSON.stringify(menus));
  localStorage.setItem('currentMenu', JSON.stringify(menus));
};
export const init = async () => {
  const res = await userService.getMsgList({
    pageSize: 10,
    pageNum: 1,
    appId: 'yunkepc',
    msgType: null,
  });
  localStorage.setItem('messageList', JSON.stringify(res?.data?.result ? res.data.result : []));
  localStorage.setItem('unreads', JSON.stringify(res.data?.isNoReadCount));
};

export const setUserPayInfo = ({
  id, orderType, visible, money,
}) => {
  localStorage.setItem('userPayInfo', JSON.stringify({
    id,
    orderType,
    visible,
    money,
  }));
};

export const clearUserPayInfo = () => {
  localStorage.setItem('userPayInfo', JSON.stringify({
    id: '',
    orderType: '',
    visible: false,
  }));
  messageStore.wscolse();
};

export const setShowUserDrawer = (showUserDrawer) => {
  localStorage.setItem('showUserDrawer', showUserDrawer);
};
export const setUserDrawerPhone = (userDrawerPhone) => {
  localStorage.setItem('userDrawerPhone', userDrawerPhone);
};

export const getSiteInfo = async () => {
  let channelInfo = localStorage.getItem('channelInfo');
  if (!channelInfo || JSON.parse(channelInfo)?.privateValue === undefined) {
    channelInfo = await userService.getChannelInfo();
    if (channelInfo) { // 接口异常处理
      localStorage.setItem('channelInfo', JSON.stringify(channelInfo));
    }
  } else {
    channelInfo = JSON.parse(channelInfo);
  }
  localStorage.setItem('siteInfo', JSON.stringify(channelInfo));
  // 添加favicon
  if (channelInfo.urlLogo) {
    let link = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');
    if (!link) {
      link = document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = channelInfo.urlLogo;
  }
};
