import { lazy } from 'react';

let deploy = {};
const modules = import.meta.globEager('../ymls/*.yml');
const company = import.meta.env.VITE_APP_PRIVATE;
Object.keys(modules).forEach(async (el) => {
  if (el.includes(company)) {
    deploy = modules[el].default;
  }
});
deploy = deploy[company]?.global;

const Statistics = lazy(() => import('@/pages/home/statistics'));
const Sign = lazy(() => import('@/pages/home/sign'));
const OldSign = lazy(() => import('@/pages/home/sign/oldSign'));
const Appstatistics = lazy(() => import('@/pages/home/appstatistics'));
const MobileLocation = lazy(() => import('@/pages/home/mobilelocation'));
const OutboundList = lazy(() => import('@/pages/home/my/Outbound'));
const MyAccount = lazy(() => import('@/pages/home/my/account'));
const ReportHome = lazy(() => import('@/pages/home/reportform/home'));
const IntelligentAssistant = lazy(() => import('@/pages/home/intelligentAssistant'));
const ReportCall = lazy(() => import('@/pages/home/reportform/call'));
const ReportMsg = lazy(() => import('@/pages/home/reportform/msg'));
const ReportWechat = lazy(() => import('@/pages/home/reportform/wechat'));
const ReportDetail = lazy(() => import('@/pages/home/reportform/detail'));
const ReportList = lazy(() => import('@/pages/home/reportform/list'));
const ReportWechatList = lazy(() => import('@/pages/home/reportform/wechatlist'));
const ReportDownload = lazy(() => import('@/pages/home/reportform/download'));
const MessageCenter = lazy(() => import('@/pages/home/messageCenter'));
const Download = lazy(() => import('@/pages/dowload'));
const MissionList = lazy(() => import('@/pages/mission/list'));
const MissionDetail = lazy(() => import('@/pages/mission/detail'));
const MissionPlan = lazy(() => import('@/pages/mission/plan'));
const ElectronicSales = lazy(() => import('@/pages/home/my/sipIntroduce/electricPin'));
const SipIntroduce = lazy(() => import('@/pages/home/my/sipIntroduce'));
const StudioCamera = lazy(() => import('@/pages/home/my/sipIntroduce/studioCamera'));
const transferOutbound = lazy(() => import('@/pages/home/my/transferOutbound'));
const OpenTransferOutbound = lazy(() => import('@/pages/home/my/sipIntroduce/specialSchool'));
const HomePC = lazy(() => import('@/pages/iframepage/index'));
const QualityTesting = lazy(() => import('@/pages/iframepage/qualityTesting'));
const ApplyOutbound = lazy(() => import('@/pages/home/my/sipIntroduce/doubleCalling'));
const ElectronList = lazy(() => import('@/pages/home/my/electronicSales/list'));
const TopupRecord = lazy(() => import('@/pages/home/my/electronicSales/topuprecord'));
const ImportList = lazy(() => import('@/pages/ImportList/index'));
const userDetail = lazy(() => import('@/pages/layout/userDetail'));
const ExpenditureDetails = lazy(() => import('@/pages/expenditureDetails/index'));
const BillingRecord = lazy(() => import('@/pages/billing-record'));
const AddBillingReacord = lazy(() => import('@/pages/billing-record/addBillingRecord'));
// const NewCall = lazy(() => import('@/pages/home/reportform/newCall'));

const NotFound = lazy(() => import('@/pages/errorPages/notFound'));
const ErrorPage = lazy(() => import('@/pages/errorPages/errorPage'));
const NotAccess = lazy(() => import('@/pages/errorPages/notAccess'));
const NewCall = lazy(() => import('@/pages/home/reportform/newCall')); // 通话分析报表
const RiskNumber = lazy(() => import('@/pages/home/riskNumber/index'));
const AnitComplaint = lazy(() => import('@/pages/home/riskNumber/antiComplaint'));

const numberCleanRecord = lazy(() => import('@/pages/home/my/numberClean'));
const Authorize = lazy(() => import('@/pages/home/authorize'));
const CallStatistics = lazy(() => import('@/pages/sensitive-statistics/index'));
const CallStatisticsList = lazy(() => import('@/pages/sensitive-statistics/detail'));
const TaskCenter = lazy(() => import('@/pages/taskCenter/index'));
const Chat = lazy(() => import('@/pages/chat/index'));
const Notufucation = lazy(() => import('@/pages/notifications/index'));
const ConvertText = lazy(() => import('@/pages/asr/convertText/index'));
const DataPanel = lazy(() => import('@/pages/dataPanel/index'));
export default [
  {
    name: '云客机器人',
    path: '/chat',
    component: Chat,
  },
  {
    name: '语音转文字',
    path: '/convertText',
    key: 'convertText',
    component: ConvertText,
  },
  {
    name: '数据统计',
    path: '/home/statistics',
    key: 'home_statistics',
    component: Statistics,
  },
  {
    name: '打卡统计',
    path: '/home/sign',
    key: '/sign',
    component: deploy?.newSign ? Sign : OldSign,
  },
  {
    name: '手机定位',
    path: '/home/mobilelocation',
    key: '/mobilelocation',
    component: MobileLocation,
  },
  {
    name: '手机统计',
    path: '/home/appstatistics',
    key: 'appstatistics',
    component: Appstatistics,
  },
  {
    name: '双向外呼',
    path: '/home/outboundList/:type',
    key: 'outboundList',
    component: OutboundList,
  },
  {
    name: '我的账户',
    path: '/expenditureDetails',
    key: '/myaccount',
    component: MyAccount,
  },
  {
    name: '报表统计',
    path: '/home/reportform/home',
    exact: true,
    key: 'reporthome',
    component: ReportHome,
  },
  {
    name: '智能助手',
    path: '/home/intelligentAssistant',
    exact: true,
    key: 'intelligentAssistant',
    component: IntelligentAssistant,
  },
  {
    name: '电话统计',
    path: '/home/reportform/call',
    exact: true,
    key: 'reportcall',
    component: deploy?.newCall ? NewCall : ReportCall,
  },
  {
    name: '短信统计',
    path: '/home/reportform/msg',
    exact: true,
    key: '/reportmsg',
    component: ReportMsg,
  },
  {
    name: '微信统计',
    path: '/home/reportform/wechat',
    exact: true,
    key: '/reportwechat',
    component: ReportWechat,
  },
  {
    name: '报表详情',
    path: '/home/reportform/:module/detail',
    exact: true,
    key: '/reportdetail',
    component: ReportDetail,
  },
  {
    name: '报表个人详情',
    path: '/home/reportform/:module/list',
    exact: true,
    key: '/reportList',
    component: ReportList,
  },
  {
    name: '报表微信个人详情',
    path: '/home/reportform/wechat/wechatlist',
    exact: true,
    key: '/reportWechatList',
    component: ReportWechatList,
  },
  {
    name: '报表导出',
    path: '/home/reportform/home/download',
    exact: true,
    key: 'reportdownload',
    component: ReportDownload,
  },
  {
    name: '消息中心',
    path: '/home/messageCenter',
    key: 'messageCenter',
    component: MessageCenter,
  },
  {
    name: '下载中心',
    path: '/dowload',
    key: 'download',
    component: Download,
  },
  {
    name: '任务列表',
    path: '/mission/list',
    key: 'missionList',
    component: MissionList,
  },
  {
    name: '任务详情',
    path: '/mission/detail',
    key: 'missionDetail',
    component: MissionDetail,
  },
  {
    name: '团队任务',
    path: '/mission/plan',
    key: 'missionPlan',
    component: MissionPlan,
  },
  {
    name: '电销卡',
    path: '/home/electronicSales',
    key: 'electronicSales',
    component: ElectronicSales,
  },
  {
    name: 'sip外呼',
    path: '/home/sipIntroduce',
    key: 'sipIntroduce',
    component: SipIntroduce,
  },
  {
    name: '座机外呼外呼',
    path: '/home/studioCamera',
    key: 'studioCamera',
    component: StudioCamera,
  },
  {
    name: '中转外呼',
    path: '/home/transferOutbound/:type',
    key: 'transferOutbound',
    component: transferOutbound,
  },
  {
    name: '开通中转外呼',
    path: '/home/OpenTransferOutbound',
    key: 'OpenTransferOutbound',
    component: OpenTransferOutbound,
  },
  {
    name: '云客产品',
    path: '/home/pc/:url',
    key: 'homePC',
    component: HomePC,
  },
  {
    name: '云客质检',
    path: '/qualityTesting',
    key: 'QualityTesting',
    component: QualityTesting,
  },
  {
    name: '双向外呼申请',
    path: '/home/applyOutbound',
    key: 'applyOutbound',
    component: ApplyOutbound,
  },
  {
    name: '电销卡激活列表',
    path: '/home/electronList',
    key: 'electronList',
    component: ElectronList,
  },
  {
    name: '电销卡充值记录',
    path: '/home/topuprecord',
    key: 'topuprecord',
    component: TopupRecord,
  },
  {
    name: '导入记录',
    path: '/import',
    key: 'importList',
    component: ImportList,
  },
  {
    name: '个人中心',
    path: '/home/userDetail',
    key: 'userDetail',
    component: userDetail,
  },
  {
    name: '收支明细',
    path: '/home/myaccount',
    key: 'myaccount',
    component: ExpenditureDetails,
  },
  {
    name: '开票记录',
    path: '/billingRecord',
    key: 'billing_record',
    component: BillingRecord,
  },
  {
    name: '申请开票',
    path: '/addBillingReacord',
    key: 'add_billing_reacord',
    component: AddBillingReacord,
  },
  {
    name: '403',
    path: '/403',
    key: '403',
    component: NotAccess,
  },
  {
    name: '404',
    path: '/404',
    key: '404',
    component: NotFound,
  },
  {
    name: 'error',
    path: '/500',
    key: '500',
    component: ErrorPage,
  },
  {
    name: '风险号检测',
    path: '/riskNumber',
    key: 'risk_number',
    component: RiskNumber,
  },
  {
    name: '防投诉服务',
    path: '/anitComplaint',
    key: 'anit_complaint',
    component: AnitComplaint,
  },
  {
    name: '号码清洗',
    path: '/numberCleanRecord',
    key: 'numberCleanRecord',
    component: numberCleanRecord,
  },
  {
    name: '授权',
    path: '/authorize',
    key: 'authorize',
    component: Authorize,
  },
  {
    name: '敏感词统计',
    path: '/callStatistics',
    key: 'callStatistics',
    exact: true,
    component: CallStatistics,
  },
  {
    name: '敏感词统计',
    path: '/callStatistics/:type',
    exact: true,
    key: 'callStatistics_detail',
    component: CallStatisticsList,
  },
  {
    name: '任务中心',
    path: '/taskCenter',
    key: 'taskCenter',
    component: TaskCenter,
  },
  {
    name: '通知中心',
    path: '/notifications',
    key: 'notifications',
    component: Notufucation,
  },
  {
    name: '数据面板',
    path: '/home/dataPanel',
    key: 'dataPanel',
    component: DataPanel,
  },

];
