import { InputNumber, Button, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { userService } from '@/service';
import messageStore from '@/utils/message-store';
import styles from './style.module.less';

const propTypes = {
  companyInfo: PropTypes.shape({
    imgUrl: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  userPayInfo: PropTypes.shape({
    id: PropTypes.string,
    money: PropTypes.number,
    orderType: PropTypes.number,
    open: PropTypes.bool,
  }),
  onChangeId: PropTypes.func,
};

const defaultProps = {
  companyInfo: {},
  userPayInfo: {
    id: '',
    money: 0,
    orderType: 4,
  },
  onChangeId: () => {},
};
const limitDecimalsF = (value) => {
  const reg = /^(-)*(\d+)\.(\d\d).*$/; return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(reg, '$1$2.$3');
};

const limitDecimalsP = (value) => {
  const reg = /^(-)*(\d+)\.(\d\d).*$/;
  return value.replace(/￥\s?|(,*)/g, '').replace(reg, '$1$2.$3');
};

class TopUp extends Component {
  constructor() {
    super();
    this.state = {
      amount: 0,
      loading: false,
    };
  }

  handleChangeClick = async () => {
    const { onChangeId } = this.props;
    this.setState(
      {
        loading: true,
      },
    );
    const { amount } = this.state;
    const data = {
      amount: amount * 100, type: 3, name: '线上充值', remark: '线上充值',
    };
    const response = await userService.addRechargeCode(data);
    this.setState(
      {
        loading: false,
      },
    );
    if (response.code === 10000) {
      onChangeId({
        ...response.data,
        money: amount * 100,
      });
      if (response.data.id) {
        messageStore.connectWS();
      }
    } else {
      message.error(response.message);
    }
  };

  render() {
    const { amount, loading } = this.state;
    const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));
    return (
      <>
        <div>
          <div className={styles.boxFlex}>
            <img className={styles.headerImg} alt="" src={companyInfo.imgUrl} />
            <span className={styles.comnameTitle}>{companyInfo.name}</span>
          </div>
          <div className={styles.rechargeBox}>
            余额充值：
            <InputNumber
              formatter={limitDecimalsF}
              parser={limitDecimalsP}
              onChange={(e) => this.setState({ amount: e || 0 })}
              style={{ width: '170px' }}
              placeholder="请输入要充值的数额"
            />
            <span className={styles.textSpan}>元</span>
          </div>
          <div className={styles.rechargeBox}>
            应付金额：
            <b>
              ¥
              {limitDecimalsF(amount)}
            </b>
          </div>
          <div className={styles.bule}>
            <p style={{ paddingTop: '16px' }}>
              <InfoCircleOutlined
                theme="filled"
                style={{ color: '#006aff' }}
                className={styles.iconStyle}
              />
              <span className={styles.Tips}>提示</span>
            </p>
            <p> 您好，暂时不支持电子发票，若想开发票请直接联系您的销售经理~</p>
          </div>
        </div>
        <div className={styles.boxfooter}>
          <Button
            loading={loading}
            onClick={() => this.handleChangeClick()}
            type="primary"
          >
            下一步
          </Button>
        </div>
      </>
    );
  }
}
TopUp.propTypes = propTypes;
TopUp.defaultProps = defaultProps;
export default TopUp;
