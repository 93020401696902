import {
  Result, Button,
} from 'antd';
/**
 * 403错误
 * @returns
 */
const NotAccess = () => (
  <Result
    status="403"
    title="403"
    subTitle="对不起，您无权访问此页面"
    extra={(
      <Button
        type="primary"
        onClick={() => {
          const menus = localStorage.getItem('permissions');
          if (menus) {
            window.location.href = '/cms/';
          } else {
            window.location.href = '/cms/auth/login';
          }
        }}
      >
        回到首页
      </Button>
    )}
  />
);

export default NotAccess;
