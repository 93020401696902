import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translation_en from './locales/en-US.json';
import translation_zh from './locales/zh-CN.json';

const resources = {
  en: {
    translation: translation_en,
  },
  zh: {
    translation: translation_zh,
  },
};

i18n.use(LanguageDetector)
  .use(initReactI18next).init({
    resources,
    // 默认语言  zh/en  中文/英文
    fallbackLng: 'zh',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: ['localStorage', 'sessionStorage', 'cookie'],
    },
  });

export default i18n;
