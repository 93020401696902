import { request } from 'yunkecom/dist/utils';
import qs from 'qs';

let deploy = {};
const modules = import.meta.glob('../ymls/*.yml', { eager: true });
const company = import.meta.env.VITE_APP_PRIVATE;
Object.keys(modules).forEach(async (el) => {
  if (el.includes(company)) {
    deploy = modules[el].default;
  }
});
deploy = deploy[company]?.http_url;

// 获取组织架构全部
export const getTreeData = () => request.postJson('/usercenter/dept/tree');

/**
 * 数据统计接口
 */
export const getHomeData = (data) => {
  if (deploy?.get_home_data_url) {
    return request.postJson(deploy?.get_home_data_url, data);
  }
  if (deploy?.new_api) {
    return request.postJson('/yunke-report-phone/pcc/home/gethomedata', data);
  }
  return request.postJson('/phone-report/home/gethomedata', data);
};

/**
 * 详情接口
 */

export const getCallDetail = (data) => {
  if (deploy?.get_call_detail_url) {
    return request.postJson(deploy?.get_home_data_url, data);
  }
  if (deploy?.new_api) {
    return request.postJson('/yunke-report-phone/pcc/home/getcalldetail', data);
  }
  return request.postJson('/phone-report/home/getcalldetail', data);
};

/**
 * 详情列表接口
 */
export const getUserDetail = (data) => {
  if (deploy?.get_user_detail_url) {
    return request.postJson(deploy?.get_user_detail_url, data);
  }
  if (deploy?.new_api) {
    return request.postJson('/yunke-report-phone/pcc/home/getuserdetail', data);
  }
  return request.postJson('/phone-report/home/getuserdetail', data);
};

/**
 * 导出接口
 */
export const getExport = (data) => {
  if (deploy?.get_export_url) {
    return request.postJson(deploy?.get_user_detail_url, data);
  }
  if (deploy?.new_api) {
    return request.postJson('/yunke-report-phone/download/getdownloadshowdetail', data);
  }
  return request.postJson('/phone-report/download/getdownloadshowdetail', data);
};

/**
 * 新报表接口
 */
export const getIndexOverView = (data) => request.postJson(
  deploy?.new_api
    ? '/yunke-report-phone/module/getIndexOverView'
    : '/phone-report/module/getIndexOverView',
  data,
);

/**
 * 新报表详情接口
 * /module/getIndexDetail
 */
export const getIndexDetail = (data) => request.postJson(
  deploy?.new_api
    ? '/yunke-report-phone/module/getIndexDetail'
    : '/phone-report/module/getIndexDetail',
  data,
);

/**
 *  更新通话报表
 * /module/refreshUserCallStatistics
 */
export const getUpdataCallStatistics = (data) => request.postJson(
  deploy?.new_api
    ? '/yunke-report-phone/module/refreshUserCallStatistics'
    : '/phone-report/module/refreshUserCallStatistics',
  data,
);

/**
 * 客户联系分析
 */
export const getcustomerContactRank = (data) => request.postForm(
  '/yunke-report-phone/module/customerContactRank',
  qs.stringify(data),
);

/**
 *导出
 */
export const exportStatic = (data) => {
  if (deploy?.downloadURL) {
    return request.get('/pc-report/download/getdownloaddata', data);
  }
  if (deploy?.new_api) {
    return request.get('/yunke-report-phone/download/getdownloaddata', data);
  }
  return request.postJson('/phone-report/download/getdownloaddata', data);
};

/**
 * 获取自定义表头
 * /yunke-report-phone/custom/Report/getHead
 */
export const getReportHead = (data) => request.get('/yunke-report-phone/custom/Report/getHead', data);

/**
 * 修改 自定义表头 /yunke-report-phone/custom/Report/setHead
 */

export const setReportHead = (data) => request.postJson('/yunke-report-phone/custom/Report/setHead', data);

/**
 * 自定义报表
 * /yunke-report-phone/custom/Report/getDataView
 */
export const getDataView = (data) => request.postJson('/yunke-report-phone/custom/Report/getDataView', data);

/**
 *导出/pc/custom/Report/exportCustomData
 */
export const exportCustomData = (data) => request.postJson('/yunke-report-phone/custom/Report/exportCustomData', data);

/**
 * /pc/custom/Report/exportDeptMonthData
 * 触达统计导出
 */
export const exportTouchData = (data) => request.download(
  '/yunke-report-phone/custom/Report/exportDeptMonthData',
  data,
);

/**
 *
 * /pc/custom/Report/getDeptMonthDataView
 * 触达统计
 *
 */

export const getTouchData = (data) => request.postJson(
  '/yunke-report-phone/custom/Report/getDeptMonthDataView',
  data,
);
