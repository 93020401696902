import { userService } from '@/service';

class MessageStore {
  constructor() {
    this.state = {};
    this.ws = null; // 与消息中心的websocket连接
    this.messageList = []; // 消息列表
    this.unreads = 0; // 未读消息数
    this.lockReconnect = false; // 是否真正建立连接
    this.timeout = 10 * 1000; // 30秒一次心跳
    this.timeoutnum = null; // 断开重连倒计时
    this.number = 0; // 积累链接次数
    this.timeoutObj = null; // 心跳心跳倒计时
    this.orderStatus = 0; // 订单支付状态
    this.orderId = '1'; // 订单 id
  }

   init = async () => {
     const res = await userService.getMsgList({
       pageSize: 10,
       pageNum: 1,
       appId: 'yunkepc',
       msgType: null,
     });
     this.messageList = res?.data?.result ? res.data.result : [];
     this.unreads = res.data?.isNoReadCount;
   }

    reconnect =() => { // 重新连接
      if (this.lockReconnect) {
        return;
      }
      this.lockReconnect = true;
      // 设置延迟请求
      if (this.timeoutnum) {
        clearTimeout(this.timeout);
      }
      this.timeoutnum = setTimeout(() => {
        this.connectWS(); // 重连
        this.lockReconnect = false;
      }, 3000);
    }

    reset =() => { // 重置心跳
      clearInterval(this.timeoutObj); // 重置时间
      this.start(); // 重启心跳
    }

   start=() => { // 开启心跳
     if (this.timeoutObj) {
       clearInterval(this.timeout);
     }
     this.timeoutObj = setInterval(() => {
     // 发送一个心跳
       if (this.ws.readyState === 1) { // 连接正常
         this.ws.send('ok');
       } else { // 重连
         this.reconnect();
       }
     }, this.timeout);
   }

   /**
   * 读某条消息
   * @param {*} id 消息id
   * @param {*} isRead 该条消息是否已读
   */
  readMsg = (id, isRead) => {
    const msg = this.messageList.find((item) => item.id === id);
    if (msg) {
    // 将该条消息标为已读
      msg.isRead = 1;
    }
    if (isRead === 0) {
    // 如果该条消息未读，则将未读总数减一
      this.unreads -= 1;
    }
  }

  wscolse = (type) => {
    this.ws?.close();
    this.lockReconnect = false;
    if (type === 2) {
      this.orderStatus = null;
    }
  }

 connectWS = () => {
   if (import.meta.env.REACT_APP_API_ENV === 'dev') {
     this.ws = new WebSocket('wss://netty.yunkecn.com:443/netty/ws?appVersion=cms');
   } else if (window.location.host === 'test.yunkecn.com') {
     this.ws = new WebSocket('wss://test.yunkecn.com:443/netty/ws?appVersion=cms');
   } else {
     this.ws = new WebSocket(`wss://netty.yunkecn.com:443/netty/ws?compnayCode=${localStorage.getItem('company')}&userId=${localStorage.getItem('userid')}&appVersion=cms`);
   }

   this.ws.onopen = () => {
     const obj = {
       WXZ_NETTY: 'USER_ONLINE_STATUS',
       appId: 'yunkepc',
       companyCode: localStorage.getItem('company'),
       userId: localStorage.getItem('userid'),
     };
     // 1表示连接已建立，可以进行通信
     if (this.ws.readyState === 1) {
       this.ws.send(JSON.stringify(obj));
       this.start();
       this.number += 1;
     }
   };
   this.ws.onmessage = (evt) => {
     this.reset();
     const { data } = evt;
     if (data.startsWith('{')) {
       const obj = JSON.parse(data);
       if (obj.pushType === 'ELINK_PAY_STATUS') {
         this.orderStatus = obj.data.orderstatus;
         return;
       }
       const { payload } = obj;
       this.orderStatus = payload?.extra?.orderStatus || 0;
       this.otherOrderId = payload?.extra?.otherOrderId || '';
     }
   };
   this.ws.onclose = () => {
     if (this.number < 10) {
       this.reconnect();
     } // 重连
   };
   this.ws.onerror = () => {
     if (this.number < 10) {
       this.reconnect();
     } // 重连
   };
 }
}

export default new MessageStore();
