import PropTypes from 'prop-types';
import styles from './style.module.less';

const propTypes = {
  companyName: PropTypes.string,
  money: PropTypes.number,
};

const defaultProps = {
  companyName: '',
  money: 0,
};
const Result = ({ companyName, money }) => (
  <div className={styles.code}>
    <div className={styles.codeWrap}>
      <img
        className={styles.codeavatar}
        alt=""
        src="https://yunke-pcfile.oss-cn-beijing.aliyuncs.com/images/success.png"
      />
      <div style={{ color: '#006AFF' }} className={styles.codeCompanyname}>充值成功</div>
      <div className={styles.codeCompanyname}>{companyName}</div>
      <div style={{ color: '#006AFF' }} className={styles.codeMoney}>
        ¥
        {money / 100}
      </div>
    </div>
  </div>
);
Result.propTypes = propTypes;
Result.defaultProps = defaultProps;
export default Result;
