import { Modal, Button } from 'antd';
import PropTypes from 'prop-types';

const propTypes = {
  onClose: PropTypes.func.isRequired,
};

const IpModal = ({ onClose }) => {
  const companyInfo = localStorage.getItem('companyInfo')
    ? JSON.parse(localStorage.getItem('companyInfo'))
    : {};
  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>工作手机 - 接口对接补充IP提醒</div>}
      open
      width="40%"
      centered
      onCancel={onClose}
      footer={[
        <div key={1} style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            style={{ textAlign: 'center', width: 100 }}
            onClick={() => {
              window.location.href = `${window.location.origin}/cms/settings/openApplication`;
              localStorage.setItem('expire', '1');
            }}
          >
            去绑定IP
          </Button>
        </div>,
      ]}
    >
      <div>
        <div style={{ border: '1px solid #ccc', margin: '10px 0', opacity: 0.5 }} />
        <h2> 尊敬的客户：</h2>
        <p style={{ fontWeight: 400, lineHeight: 2 }}>
          <div>您好！</div>
          <div>目前，有大量企业正在通过我们的数据开放接口与系统进行对接。</div>
          <div style={{ textIndent: '2rem' }}>
            为进一步加强系统安全性和稳定性，现系统新上线了IP管控功能。即日起，所有需要与我们的数据开放接口进行对接的企业，在对接前，请务必先绑定IP地址，方可正常对接开放接口。
          </div>
          <div style={{ color: 'red', textIndent: '2rem' }}>
            我们设定了一个月的期限（自本公告发布之日起至
            {companyInfo?.openIpDate}
            ），在此期限内，尚未填写并绑定
            IP 的企业，将无法再使用数据开放接口。
          </div>
          <div style={{ textIndent: '2rem' }}>
            请各企业高度重视，尽快完成 IP
            填写工作，以确保系统的正常运行和服务的持续提供。如有疑问或需要技术支持，请随时与我们联系。
          </div>
          <div>感谢您的理解与配合！</div>
          <div> 祝您工作顺利！</div>
          <div style={{ fontWeight: 500, marginTop: 20 }}> 操作如下：</div>
          <div>
            1、点击【去绑定IP】，会自动跳转至【接口申请配置功能】，在开发设置中填写IP地址并提交申请。
          </div>
          <div>
            2、手动点击系统：【设置】- 【接口申请配置功能】，在开发设置中填写IP地址并提交申请。
          </div>
        </p>
        <div style={{ border: '1px solid #ccc', margin: '10px 0', opacity: 0.5 }} />

      </div>
    </Modal>
  );
};
IpModal.propTypes = propTypes;
export default IpModal;
