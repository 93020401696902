import { lazy } from 'react';

const Clues = lazy(() => import('@/pages/clues'));

export default [
  {
    name: '线索',
    path: '/clues/:project/:url',
    key: 'cluesurl',
    component: Clues,
  },
  {
    name: '线索',
    path: '/clues/:project',
    key: 'clues',
    component: Clues,
  },
];
