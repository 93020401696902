import React, { Component } from 'react';
import dayjs from 'dayjs';
import {
  Dropdown, Menu, Tabs, List, Badge, Avatar, Modal, message,
} from 'antd';
import {
  BellOutlined, UserOutlined, SwapOutlined,
  PhoneOutlined, LogoutOutlined, ExclamationCircleOutlined, DownloadOutlined, ImportOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import i18n from 'i18next';
import {
  setAllMsgRead,
  listUserByPhone, login, getMenu, homeLogout,
} from '@/service/user-service';
import certified from '@/public/images/certified.png';
import MessageDetail from '@/pages/home/messageCenter/detail';
import { logout, clearLocalStorage } from '@/global';
import CompanyDrawer from './companyDrawer';
import SearchMenu from './searchMenu/index';
import { init } from '../../utils/index';

let deploy = {};

const modules = import.meta.glob('../../ymls/*.yml', { eager: true });
Object.keys(modules).forEach((el) => {
  if (el.includes(import.meta.env.VITE_APP_PRIVATE)) {
    deploy = modules[el].default;
  }
});
deploy = deploy[import.meta.env.VITE_APP_PRIVATE].global;

const propTypes = {
};
const defaultProps = {
};
class TopRight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompanyDrawer: false,
      showMsgDrawer: false,
      msgId: '',
      userList: JSON.parse(localStorage.getItem('userList')) || [],
      searchTrue: false,
    };
  }

  componentDidMount() {
    const { cellphone } = JSON.parse(localStorage.getItem('userData')) || {};
    document.addEventListener('keydown', this.handleKeyDown);
    this.loadUser(cellphone);
  }

  handleKeyDownFc = () => {
    this.setState({ searchTrue: false });
  }

  handleKeyDown = (e) => {
    if (e.ctrlKey && e.keyCode === 70) {
      e.preventDefault();
      this.setState({ searchTrue: true });
    }
  }

  loadUser = async (phone) => {
    const { userList } = this.state;
    if (userList.length) return;
    const response = await listUserByPhone({ phone, queryScope: 'company' });
    if (response.success) {
      this.setState({ userList: response.data });
      localStorage.setItem('userList', JSON.stringify(response.data));
    }
  }

  switchUser = async (companyCode, domain) => {
    const { cellphone } = JSON.parse(localStorage.getItem('userData'));
    const { channelCode } = JSON.parse(localStorage.getItem('channelInfo'));
    if (`${window.location.protocol}//${window.location.host}` !== domain && ['crm2.yunkecn.com'].includes(window.location.host)) {
      window.location.href = `${domain}/cms/auth/login?phone=${cellphone}&password=${localStorage.getItem('p')}&companyCode=${companyCode}&encrypt=1`;
    } else {
      const response = await login({
        phone: cellphone, password: localStorage.getItem('p'), channelCode, companyCode,
      });
      if (response.success) {
        clearLocalStorage();
        localStorage.setItem('userid', response.data.id);
        localStorage.setItem('company', response.data.companyCode);
        localStorage.setItem('phoneNumber', cellphone); // 快鲸多账户选择需要手机号
        if (response?.data?.thirdToken) {
          localStorage.setItem('thirdToken', response?.data?.thirdToken);
        }
        this.setState({ userList: [] }, () => {
          this.loadUser(response.data.phone);
        });
        const menuInfo = await getMenu();
        if (Array.isArray(menuInfo)) {
          localStorage.setItem('permissions', JSON.stringify(menuInfo));
          if (menuInfo.length > 0) {
            window.location.href = menuInfo[0].url;
          }
        }
      } else {
        message.error(response.message);
      }
    }
  }

  translation = () => {
    const translationList = [{ key: 'zh', label: '🇨🇳 中文' }, { key: 'en', label: '🇺🇸 英文' }];
    return (
      <Menu selectedKeys={localStorage.getItem('i18nextLng')}>
        {translationList
          .map((item) => (
            <Menu.Item
              key={item.key}
              onClick={() => {
                i18n.changeLanguage(item.key);
                window.location.reload();
              }}
            >
              {item.label}
            </Menu.Item>
          ))}
      </Menu>
    );
  }

  renderAvatarList = () => {
    const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));
    // const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};
    const channelInfo = JSON.parse(localStorage.getItem('channelInfo'));
    return (
      <Menu>
        <Menu.Item
          key="user"
          onClick={() => {
            window.location.href = '/cms/home/userDetail';
          }}
        >
          <div className="flex">
            <span className="text-xl font-medium">{userInfo.realName}</span>
            {/* <img className="w-3 h-3 ml-4 mt-3" src={IconEdit} alt="" /> */}
          </div>
        </Menu.Item>
        <Menu.Item
          key="company"
          onClick={() => { this.setState({ showCompanyDrawer: true }); }}
        >
          <div className="flex">
            <img className="w-3 h-4 mr-3 mt-1" src={certified} alt="" />
            <span className="overflow-ellipsis">{companyInfo?.name}</span>
            {/* <img className="w-3 h-3 ml-3 mt-1" src={IconEdit} alt="" /> */}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item icon={<UserOutlined />} key="userDetail">
          <a href="/cms/home/userDetail">
            个人中心
          </a>
        </Menu.Item>
        {deploy.my_account && (userInfo.userType === 1 || userInfo.userType === 2)
          && (channelInfo?.allowCharge === 1 || window.location.host === 'crm.haophone.cn') && (
            <Menu.Item icon={<UserOutlined />} key="account">
              <a href="/cms/home/myaccount">
                我的账户
              </a>
            </Menu.Item>
        )}
        <Menu.Item icon={<DownloadOutlined />} key="dowload">
          <a href="/cms/dowload">
            下载中心
          </a>
        </Menu.Item>
        {
          deploy.import_list && (
            <Menu.Item icon={<ImportOutlined />} key="import">
              <a href="/cms/import">
                导入记录
              </a>
            </Menu.Item>
          )
        }

        {deploy.dowload_soft_phone && (
          <Menu.Item icon={<PhoneOutlined />} key="soft_phone">
            <a
              href="https://yunke-pcfile.oss-cn-beijing.aliyuncs.com/d/telsoft.rar"
              target="_blank"
              rel="noreferrer"
            >
              下载软电话
            </a>
          </Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item
          key="exit"
          icon={<LogoutOutlined />}
          onClick={() => {
            Modal.confirm({
              title: '您确定要退出登录吗？',
              icon: <ExclamationCircleOutlined />,
              okText: '确定',
              cancelText: '取消',
              onOk: async () => {
                if (deploy?.logout) {
                  await homeLogout();
                }
                if (window.location.host === 'ac-phone.stg.avatr.com') {
                  logout('https://admin.stg.avatr.com/user/login');
                } else if (window.location.host === 'ac-phone.avatr.com') {
                  logout('https://admin.avatr.com/user/login');
                } else {
                  logout('/cms/auth/login');
                }
              },
            });
          }}
        >
          退出登录
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    // const {
    //   permissions,
    // } = this.props;
    const {
      showCompanyDrawer, showMsgDrawer, msgId, userList, searchTrue,
    } = this.state;
    const messageList = JSON.parse(localStorage.getItem('messageList')) || [];
    const unreads = JSON.parse(localStorage.getItem('unreads')) || 0;
    const companyInfo = JSON.parse(localStorage.getItem('companyInfo'));
    const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};

    return (
      <>
        <div className="flex items-center">
          <SearchMenu searchTrue={searchTrue} handleKeyDown={this.handleKeyDownFc} />
          {userList.length > 1 && localStorage.getItem('p') ? (
            <div className="mr-4 cursor-pointer">
              <Dropdown
                overlay={(
                  <Menu selectedKeys={[companyInfo?.code || '']}>
                    {userList
                      .map((item) => (
                        <Menu.Item
                          key={item.companyCode}
                          onClick={() => {
                            if (item.companyCode === companyInfo.code) return;
                            this.switchUser(item.companyCode, item.domain);
                          }}
                        >
                          <Avatar style={{ verticalAlign: 'middle', background: '#227ffc' }} size="small">
                            {item?.company?.substring(0, 1)}
                          </Avatar>
                          {' '}
                          {item.company}
                        </Menu.Item>
                      ))}

                  </Menu>
                )}
              >
                <UserSwitchOutlined style={{ color: '#ccc', fontSize: 24 }} />
              </Dropdown>
            </div>
          ) : null}
          {deploy.message_center && (
            <div className="mr-4 cursor-pointer">
              <Dropdown
                overlay={(
                  <Menu>
                    <Menu.Item>
                      <Tabs>
                        <Tabs.TabPane tab="消息中心">
                          <List
                            style={{
                              width: 300, height: 200, overflow: 'scroll',
                            }}
                            size="small"
                            split={false}
                            dataSource={messageList}
                            renderItem={(item) => {
                              let msgContent = {};
                              try {
                                msgContent = JSON.parse(item.msgContent);
                              } catch (e) {
                                msgContent = {
                                  summary: item.msgContent,
                                };
                              }
                              return (
                                <List.Item
                                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                                  onClick={() => {
                                    this.setState({
                                      showMsgDrawer: true,
                                      msgId: item.id,
                                    });
                                  }}
                                >
                                  <div style={{ fontSize: 13, whiteSpace: 'normal' }}>
                                    {item.isRead === 0 && <Badge style={{ marginLeft: -14 }} status="error" />}
                                    [风控通知]
                                    {msgContent.summary}
                                  </div>
                                  <div style={{ fontSize: 12, color: '#999' }}>
                                    {dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')}
                                  </div>
                                </List.Item>
                              );
                            }}
                          />
                          <div className="flex justify-certer px-8 pt-2 border-t" style={{ justifyContent: 'center' }}>
                            <div
                              role="button"
                              tabIndex="0"
                              onKeyPress={() => { }}
                              onClick={async () => {
                                await setAllMsgRead({});
                                init();
                              }}
                            >
                              全部已读
                            </div>

                          </div>
                        </Tabs.TabPane>
                      </Tabs>
                    </Menu.Item>
                  </Menu>
                )}
                trigger={['click']}
              >
                <Badge
                  count={unreads}
                  style={{
                    boxShadow: 'none',
                  }}
                >
                  <BellOutlined style={{ color: '#ccc', fontSize: 24 }} />
                </Badge>
              </Dropdown>
            </div>
          )}

          <div className="mt2 mr-4">
            <Dropdown className="mt-2" overlay={this.renderAvatarList()}>
              <Avatar
                icon={<UserOutlined />}
                src={userInfo.headImg}
                alt="用户头像"
              />
            </Dropdown>
          </div>
          {
            window.location.host.startsWith('test') && ( // 暂时判断
              <div>
                <Dropdown overlay={this.translation()}>
                  <SwapOutlined />
                </Dropdown>
              </div>
            )
          }
        </div>
        <CompanyDrawer
          open={showCompanyDrawer}
          onClose={() => this.setState({ showCompanyDrawer: false })}
        />
        {/* <UserDrawer
          permissions={permissions}
        /> */}
        <MessageDetail
          showDrawer={showMsgDrawer}
          msgId={msgId}
          onClose={() => {
            this.setState({
              showMsgDrawer: false,
            });
          }}
        />
      </>
    );
  }
}

TopRight.propTypes = propTypes;
TopRight.defaultProps = defaultProps;

export default TopRight;
